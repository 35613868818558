import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import './UserManagementPage.css'; // 引入样式文件
import CustomDialog from './CustomDialog';
import Select from 'react-select';


const TLSUserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // 跟踪选中的用户
  const [selectedUser, setSelectedUser] = useState(null); // 用于编辑用户信息
  const [isEditing, setIsEditing] = useState(false); // 是否处于编辑模式
  const [isAdding, setIsAdding] = useState(false); // 是否处于新增模式
  const [page, setPage] = useState(0); // 当前页码，默认为第一页
  const [totalPages, setTotalPages] = useState(1); // 总页数，默认为1
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const usersCacheRef = useRef({}); // 使用 useRef 定义持久化引用
  const [editorContent, setEditorContent] = useState("");
  const [statusMap, setStatusMap] = useState({}); // userId => statusText
  
  useEffect(() => {
    const fetchAllStatuses = async () => {
      const results = await Promise.all(
        users.map(async (user) => {
          const statusText = await getStatus(user.id);
          return { userId: user.id, statusText };
        })
      );

      const newMap = {};
      results.forEach(({ userId, statusText }) => {
        newMap[userId] = statusText;
      });

      setStatusMap(newMap);
    };

    fetchAllStatuses();
  }, [users]);

  const handleEditorChange = (e) => {
    setEditorContent(e.target.value);
  };

  useEffect(() => {
    fetchUsers();
  }, [searchTerm, page]); // 当页码改变时重新获取用户数据


  const handleAddUserbyGpt = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        "http://92.119.127.236:8080/vfs-visa/api/create-users-by-gpt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
             Authorization: token,
          },
          body: JSON.stringify({ content: editorContent }),
        }
      );
      
      if (response.ok) {
        setDialogContent('添加客户成功');
        setDialogOpen(true);
      } else {
        //alert("用户创建失败");
        setDialogContent('Failed to add user. Please try again later.');
      }
    } catch (error) {
      //alert("请求失败: " + error.message);
      setDialogContent('Failed to add user. Please try again later.');
    }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token'); // 从本地存储中获取token
      const response = await axios.get(`http://romi.one:8080/vfs-visa/api/users?accountName=${searchTerm}&page=${page}&size=15`, {
        headers: {
          Authorization: token // 将token添加到请求头中
        }
      });
      setTotalPages(response.data.totalPages)
      setUsers(response.data.content);

      for (const user of response.data.content) {
        usersCacheRef[user['id']] = user
      }
      console.log(usersCacheRef)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1); // 下一页
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1); // 上一页
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  
  const handleStatus = async (user) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://romi.one:8080/vfs-visa/api/users/appointments?uid=${user.id}`, {
        headers: {
          Authorization: token,
        },
      });

      if (response.data.length > 0) {
        let message = `用户 ${user.lastName} ${user.firstName} 的预约数量: ${response.data.length}\n\n`;

        response.data.forEach((appointment, index) => {
          const { bookDate, cancelStatus } = appointment;
          const formattedDate = formatDateDetail(bookDate);
          const statusText = cancelStatus === 0 ? "已预订" : "已取消";
          message += `预约 ${index + 1}:\n日期: ${formattedDate}\n状态: ${statusText}\n\n`;
        });

        setDialogContent(message);
        setDialogOpen(true);
      } else {
        setDialogContent('未找到该用户的预约信息。');
        setDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching status:', error);
      setDialogContent('获取状态时出错，请稍后再试。');
      setDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveUser = async (updatedUser) => {
    try {
      if (isAdding) {
        // 新增用户
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        await axios.post(`http://romi.one:8080/vfs-visa/api/users`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        setDialogContent('添加客户成功');
        setDialogOpen(true);
      } else {
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        const response = await axios.put(`http://romi.one:8080/vfs-visa/api/users/${updatedUser.id}`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        console.log('User updated:', response.data);
      }
      setIsEditing(false);
      setSelectedUser(null);
      setIsAdding(false);
      fetchUsers(); // 保存后刷新用户列表
    } catch (error) {
      console.error('Error saving user:', error);
      setDialogContent('Failed to save user. Please try again later.');
      setDialogOpen(true);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedUser(null);
  };

  const handleCheckboxChange = (userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    if (selectedIndex === -1) {
      setSelectedUsers([...selectedUsers, userId]); // 添加到选中列表
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId)); // 从选中列表中移除
    }
  };

  const handleAddUser = () => {
    console.log('Adding a new user...');
    setIsAdding(true);
    setSelectedUser(
      {
        centerName: '伦敦',
        firstName: '',
        lastName: '',
        gender:'male',
        nationality:'china',
        dateOfBirth: '2000-01-01',
        phoneCountryCode:'44',
        email: '', phoneNo: '',
        passportNo:'',
        passportExpiryDate:'2034-01-01',
        expectedVisaSubmitStart: formatDate(new Date().toISOString()),
        expectedVisaSubmitEnd: formatDate(new Date().toISOString()),
        status: 0,
        note: '',
        socialAccount:'',
        coverLetter:null
        // paymentAmount: ''
      }
    ); // 清空选中用户数据
    setIsEditing(true);
  };

  const handleExport = () => {
    // 将ISO日期转换为MM/DD/YYYY格式的函数
    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    };
    // 将原始数据转换为所需格式
    const exportedUsers = selectedUsers.map(userId => {
      const user = usersCacheRef[userId];
      return {
        id: user.id,
        enable: 1,
        birth_date: formatDate(user.birthday),
        email: user.email,
        phone: user.phone,
        first_name: user.firstName,
        last_name: user.lastName
      };
    });
  
    // 输出转换后的数据
    console.log('Exported users:', exportedUsers);

    const data = JSON.stringify(exportedUsers, null, 2);
  
    const blob = new Blob([data], { type: 'application/json' });
    saveAs(blob, 'data.json');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  function getStatusText(status) {
    switch (status) {
      case 0:
        return '未完成';
      case 1:
        return '已完成';
      case 2:
        return '已取消';
      default:
        return '未知状态';
    }
  }

  const formatDateDetail = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    const hours = date.getHours().toString().padStart(2, '0'); // 将小时补齐为两位数
    const minutes = date.getMinutes().toString().padStart(2, '0'); // 将分钟补齐为两位数
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteUser = async (userId) => {
    const token = localStorage.getItem('token'); // 获取token
    if (!window.confirm("确定要删除此用户吗？")) return;
  
    try {
      await axios.delete(`http://romi.one:8080/vfs-visa/api/users/${userId}`, {
        headers: {
          Authorization: token,
        },
      });
      setDialogContent('添加客户成功');
      setDialogOpen(true);
      // 从用户列表中移除被删除的用户
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error('删除用户失败:', error);
      setDialogContent('删除用户失败，请重试。');
      setDialogOpen(true);
    }
  };

  const checkStatus = async (user) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://romi.one:8080/vfs-visa/api/vfs-bookings/user/${user.id}`, {
        headers: {
          Authorization: token,
        },
      });
  
      // status 映射到中文
      const statusMap = {
        0: '进入等待列表',
        1: '已通知',
        2: '预定成功',
        3: '预约失效',
      };

      if (response.data.length > 0) {
        // 过滤出 status 不等于 3 的
        const filtered = response.data.filter(item => item.status !== 3);
  
        if (filtered.length > 0) {
          const first = filtered[0];
  
          // 将 paymentUrl 格式化为超链接
          const paymentUrl = `<a href="${first.paymentUrl}" target="_blank" rel="noopener noreferrer">点击支付</a>`;
  
          // 获取 status 对应的中文描述
          const statusDescription = statusMap[first.status] || '未知状态';
  
          // 将对象格式化成带缩进的 JSON 字符串用于显示
          const message = `
            id: ${first.id}
            userId: ${first.userId}
            accountId: ${first.accountId}
            urn: ${first.urn}
            paymentUrl: ${paymentUrl}
            paymentAmount: ${first.paymentAmount}
            createdAt: ${first.createdAt}
            updatedAt: ${first.updatedAt}
            bookingDate: ${first.bookingDate}
            status: ${statusDescription}
          `;
  
          setDialogContent(message.trim());
          setDialogOpen(true);
        } else {
          setDialogContent('没有 status != 3 的记录');
          setDialogOpen(true);
        }
      } else {
        setDialogContent('未找到该用户的预约信息。');
        setDialogOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error fetching users:', error);
        setDialogContent('获取状态时出错，请稍后再试。');
        setDialogOpen(true);
      }
    }
  };

  const getStatus = async (id) => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`http://romi.one:8080/vfs-visa/api/vfs-bookings/user/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  
    const statusMap = {
      0: '进入等待列表',
      1: '已通知',
      2: '预定成功',
      3: '预约失效',
    };
  
    if (response.data.length > 0) {
      const filtered = response.data.filter(item => item.status !== 3);
      if (filtered.length > 0) {
        const first = filtered[0];
        return statusMap[first.status] || '未知状态';
      }
    }
    return '无记录';
  };


  return (
    <div>
      <div className="container">
      <h2 className="title-style">VFS用户管理</h2>
      <div className="content-wrapper">
        <div className="left-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by social account..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="button-container">
            <button onClick={handleAddUser}>新增用户</button>
          </div>
        </div>

        <div className="right-container">
          <div className="search-container">
            <textarea
              placeholder="请输入用户信息..."
              value={editorContent}
              onChange={handleEditorChange}
              rows={1}
              style={{
                resize: "none",
                overflow: "hidden",
                width: "100%",
              }}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
          </div>
          <div className="button-container">
            <button onClick={handleAddUserbyGpt}>新增用户 by GPT</button>
          </div>
        </div>
      </div>
    </div>
      {isEditing ? (
        <UserForm user={selectedUser} onSave={handleSaveUser} onCancel={handleCancelEdit} />
      ) : (
        <div>
          <table className="user-table"> {/* 添加类名 */}
            <thead>
              <tr>
                <th>选择</th> {/* 添加选中列 */}
                <th>ID</th>
                <th>递签中心</th>
                <th>社交账户</th>
                <th>名</th>
                <th>姓</th>
                <th>性别</th>
                <th>国籍</th>
                <th>生日📅</th>
                <th>邮箱📮</th>
                <th>电话📱</th>
                <th>护照</th>
                <th>coverLetterId</th>
                <th>护照过期时间📅</th>
                <th>期望递签日期📅</th>
                <th>订单状态</th>
                {/* <th>支付金额💰</th> */}
                <th>预约信息</th>
                <th>备注📓</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(user.id)}
                      checked={selectedUsers.includes(user.id)}
                    />
                  </td>
                  <td>{user.id}</td>
                  <td>{user.centerName}</td>
                  <td>{user.socialAccount}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.gender}</td>
                  <td>{user.nationality}</td>
                  <td>{formatDate(user.dateOfBirth)}</td>
                  <td>{user.email}</td>
                  <td>+{user.phoneCountryCode} {user.phoneNo}</td>
                  <td>{user.passportNo}</td>
                  <td>{user.coverLetter}</td>
                  <td>{formatDate(user.passportExpiryDate)}</td>
                  <td>{formatDate(user.expectedVisaSubmitStart)}至{formatDate(user.expectedVisaSubmitEnd)}</td>
                  <td>{getStatusText(user.status)}</td>
                  <td>{statusMap[user.id] || '加载中...'}</td>
                  {/* <td>{user.paymentAmount}</td> */}
                  <td>{user.note || '无'}</td>
                  <td>
                  <div className="button-container2">
                        <button onClick={() => checkStatus(user)}>状态</button>
                        <CustomDialog isOpen={isDialogOpen} onClose={closeDialog} title="操作状态" message={dialogContent} />
                    <button onClick={() => handleEditUser(user)}>编辑</button>
                    {/* <button onClick={() => handleDeleteUser(user.id)}>删除</button> */}
                  </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="button-container">
            <button onClick={handlePrevPage} disabled={page === 0}>上一页</button>
            <button onClick={handleNextPage} disabled={page === totalPages - 1}>下一页</button>
            <button onClick={handleExport}>导出</button> {/* 添加导出按钮 */}
          </div>
        </div>
      )}
    </div>
  );
};

const UserForm = ({ user, onSave, onCancel }) => {

  const countryOptions = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Brunei', label: 'Brunei' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cabo Verde', label: 'Cabo Verde' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo (Congo-Brazzaville)', label: 'Congo (Congo-Brazzaville)' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Eswatini', label: 'Eswatini' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'Laos', label: 'Laos' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libya', label: 'Libya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Micronesia', label: 'Micronesia' },
    { value: 'Moldova', label: 'Moldova' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'North Korea', label: 'North Korea' },
    { value: 'North Macedonia', label: 'North Macedonia' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestine', label: 'Palestine' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Korea', label: 'South Korea' },
    { value: 'South Sudan', label: 'South Sudan' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syria', label: 'Syria' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Timor-Leste', label: 'Timor-Leste' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States', label: 'United States' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Vatican City', label: 'Vatican City' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' }
  ];

  
const centerOptions = [
  { value: 'VFS_Finland_Ireland_Tourist_Visa', label: 'VFS_Finland_Ireland_Tourist_Visa' },
  { value: 'VFS_Austria_Ireland_Tourist_Visa', label: 'VFS_Austria_Ireland_Tourist_Visa' },
  { value: 'VFS_ITALY_London_Tourist_Visa', label: 'VFS_ITALY_London_Tourist_Visa' },
  { value: 'VFS_Helan_Ireland_Tourist_Visa', label: 'VFS_Helan_Ireland_Tourist_Visa' },
  { value: 'VFS_Helan_UK_Tourist_Visa', label: 'VFS_Helan_UK_Tourist_Visa' },
  { value: 'VFS_FRA_Melbourne_Tourist_Visa', label: 'VFS_FRA_Melbourne_Tourist_Visa' },
  { value: 'VFS_Denmark_Ireland_Tourist_Visa', label: 'VFS_Denmark_Ireland_Tourist_Visa' },
  { value: 'VFS_Iceland_Ireland_Tourist_Visa', label: 'VFS_Iceland_Ireland_Tourist_Visa' },
  { value: 'VFS_Croatia_Ireland_Tourist_Visa', label: 'VFS_Croatia_Ireland_Tourist_Visa' }
];

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' }
];
  // 使用对象的解构和对象展开运算符设置默认值
  const [formData, setFormData] = useState({ ...user, bookingStatus: user ? user.bookingStatus : '未完成',
    expectTime: user.expectTime });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Name:', name);  // 应输出 'expectTime'
    console.log('Value:', value);  // 应输出选中的时间值
    // 使用对象展开运算符确保保留用户未编辑的字段
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit}>
        <label>
          递签中心:
          <Select
          options={centerOptions}
          value={centerOptions.find(option => option.value === formData.centerName)}
          onChange={(selectedOption) =>
            handleChange({ target: { name: 'centerName', value: selectedOption?.value || '' } })
          }
          placeholder="请选择递签中心"
          isClearable
        />
        </label>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <label>
          社交账户:
          <input type="text" name="socialAccount" value={formData.socialAccount} onChange={handleChange} />
        </label>
        <label>
          名:
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
        </label>
        <label>
          姓:
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
        </label>
        <label>
          性别:
        <Select
          options={genderOptions}
          value={genderOptions.find(option => option.value === formData.gender)}
          onChange={(selectedOption) =>
            handleChange({ target: { name: 'gender', value: selectedOption?.value || '' } })
          }
          placeholder="请选择性别"
          isClearable
        />
        </label>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <label>
          国籍:
          <Select
            name="nationality"
            options={countryOptions}
            value={countryOptions.find(option => option.value === formData.nationality)}
            onChange={(selectedOption) =>
              handleChange({
                target: {
                  name: 'nationality',
                  value: selectedOption?.value || ''
                }
              })
            }
            isClearable
            placeholder="请选择国家"
          />
        </label>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <label>
          生日:
          <input type="date" name="dateOfBirth" value={formatDate(formData.dateOfBirth)} onChange={handleChange} />
        </label>
        <label>
          邮箱:
          <input type="text" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <label>
          电话区号:
          <input type="text" name="phoneCountryCode" value={formData.phoneCountryCode} onChange={handleChange} />
        </label>
        <label>
          电话:
          <input type="text" name="phoneNo" value={formData.phoneNo} onChange={handleChange} />
        </label>
        <label>
          护照:
          <input type="text" name="passportNo" value={formData.passportNo} onChange={handleChange} />
        </label>
        <label>
          coverLetterId:
          <input type="text" name="coverLetter" value={formData.coverLetter} onChange={handleChange} />
        </label>
        <label>
          护照过期时间:
          <input type="date" name="passportExpiryDate" value={formatDate(formData.passportExpiryDate)} onChange={handleChange} />
        </label>
        <label>
          期望开始递签日期:
          <input type="date" name="expectedVisaSubmitStart" value={formatDate(formData.expectedVisaSubmitStart)} onChange={handleChange} />
        </label>
        <label>
          期望结束递签日期:
          <input type="date" name="expectedVisaSubmitEnd" value={formatDate(formData.expectedVisaSubmitEnd)} onChange={handleChange} />
        </label>
        <label>
          订单状态:
          <select name="status" value={formData.status} onChange={handleChange}>
            <option value="0">未完成</option>
            <option value="1">已完成</option>
            <option value="2">已取消</option>
          </select>
        </label>
        {/* <label>
          支付金额:
          <input type="text" name="paymentAmount" value={formData.paymentAmount} onChange={handleChange} />
        </label> */}
        <label>
          备注:
          <input type="text" name="note" value={formData.note} onChange={handleChange} />
        </label>
        <button className="custom-button" type="submit">保存</button>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <button className="custom-button" type="button" onClick={onCancel}>取消</button>
      </form>
    </div>
  );
};


export default TLSUserManagementPage;
