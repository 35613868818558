import React from 'react';
import './CustomDialog.css';

const CustomDialog = ({ isOpen, onClose, title, message }) => {
  if (!isOpen) return null;

  // 将message内容按换行符拆分为数组
  const messageLines = message.split('\n');

  return (
    <div className="dialog-overlay">
      <div className="dialog-box">
        <h3 className="dialog-title">{title}</h3>
        <div className="dialog-content">
          {messageLines.map((line, index) => (
            // 使用 dangerouslySetInnerHTML 来渲染 HTML 内容
            <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
          ))}
        </div>
        <button className="dialog-close-button" onClick={onClose}>关闭</button>
      </div>
    </div>
  );
};

export default CustomDialog;
